import ContentImage from "@/shared/ContentImage";
import { LinkAbsolute } from "@/shared/LinkAbsolute";
import Slider from "@/shared/Slider";
import {ContainerAuto} from "@/shared/ContainerAuto";
import { getCustomImage, getImageByClippings } from "helpers";
import { SlideContainer, VisualArticleSlide } from "./styles";
import { clickContent } from "@/shared/Analitics/GoogleAnalytics4";
import ContentIcon from "@/shared/ContentIcon";
import Separator from "@/shared/Separator";
import { Fragment } from "react";
import { useRouter } from "next/router";
const GallerySlide = (props) => {

  const router = useRouter();
  const { content, title: containerTitle, limit, withTitle = true, showAmount = true } = props;
  if (!Array.isArray(content) || content.length <= 0) return <></>;

  const { url, title, images } = content[0];

  const clippingsRequired = {
    desktop: [
      "Nota Generica",
      "Nota 1 y 2",
      "generico_860x575",
      "Apertura_full",
    ],
    mobile: ["Mobile Visuales", "col_listado_1x1"],
  };

  const arrow = <ContentIcon fill="#000" width="10px" height="21px" nameIcon='chevronUp' />;
  
  let imagesWeb =
    images &&
    Array.isArray(images) &&
    images.slice(0, limit).map((image, index) => {
      if (image.value && image.value.clippings) {
        let imageSlide = getImageByClippings(
          clippingsRequired,
          image.value.clippings
        );
        if (
          props.selectedImageId &&
          props.customClippings &&
          props.customClippings[props.selectedImageId]
        ) {
          imageSlide = getCustomImage(
            props.selectedImageId,
            props.customClippings,
            imageSlide.mobile
          );
        }
        const amount = showAmount ? `${index+1} de ${limit} / ` : "";
        return (
          <Fragment key={`slide${index}`}>
            <picture>
              <ContentImage data={imageSlide} alt={title} />
            </picture>
            <span>{`${amount}${image.epigraphe}`}</span>
          </Fragment>
        );
      }
    });
    const separator = containerTitle ? containerTitle : title ? title : "Historias visuales";
  return (
  
      <SlideContainer>
        {withTitle && <Separator title={separator} link={url} />}
        <LinkAbsolute href={url} target="_self" aria-label={title} onClick={() => clickContent({contentType: title},url,router.asPath)}/>
        <VisualArticleSlide>
          <Slider items={imagesWeb} arrowLeft={arrow} arrowRight={arrow}/>
        </VisualArticleSlide>
      </SlideContainer>
   
  );
};

export default GallerySlide;
