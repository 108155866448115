import styled from "styled-components";
import { breakpoints, Font, Color, ColorDark } from "@/shared/Constants";
import { backgroundPhoto } from "../mixins-modules"; 
const arrow = `    
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;  
  z-index: 5;`;

export const SlideContainer = styled.div`
  position: relative;
  max-width: 1316px;
  margin: 1rem auto;
  width: 100%;
  overflow: hidden;
  picture{
    position: relative;
    height: 100%;
    display: block;
    &::before{
      ${backgroundPhoto}
      }
  }
  ${breakpoints.tablet} {
    max-width: 972px;
  }
  ${breakpoints.phone} {
    width: 100%;
    margin: 10px auto;
    hr + div{
      height: 42px;
    }
  }

  .slide-arrow-left{
    background-color: ${Color.white};
    ${arrow}
    left: 15px;
    ${breakpoints.mobileFirst} {
      left: 30px;
    }
    svg{
      transform: rotate(90deg);
    }
  }
  .slide-arrow-right{
    background-color: ${Color.white};
    ${arrow}
    right: 15px;
    ${breakpoints.mobileFirst} {
      right: 30px;
    }
    svg{
      transform: rotate(270deg);
    }
  }
  .slide-active{
    
      position: relative;
      span{
        position: absolute;
        width: 100%;
        bottom: 15px;
        text-align: center;
        left: 0;
        color: ${Color.white};
        font-size: 15px;
        line-height:20px;
        padding: 0 40px;
        ${Font.sansBold};
        overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          ${breakpoints.darkMode} {
            [data-theme='dark'] & {
            color: ${ColorDark.textLight};
            }
          }
        ${breakpoints.mobileFirst} {
          font-size: 18px;
          bottom: 30px;
          color: ${Color.white};
        }
      }
    
  }
  .GalleryTitle{
    color: ${Color.grey};
  }
  .slide-active span{
    ${breakpoints.phone} {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
    }  
  }
  h2.sep{
    
    visibility:hidden;
    ${breakpoints.mobileFirst} {
      ${Font.sansRegular};
      visibility: visible;
    }
    &:before{
      content: "Historias visuales. ";
      ${Font.sansBold};
      visibility: visible;    
      /* top: 25px; */
      /* position: absolute; */
      font-size: 18px;
      ${breakpoints.mobileFirst} {
        font-size: 20px;
        /* position: relative; */
        top: 0;
      }
    }
  }
  /* padding-top: 50.3875968992248%;
  position: relative;
  overflow: hidden;

  ${breakpoints.phone} {
    padding-top: 100%;
    width: 100vw;
    left: -29px;
  }
  .back {
    color: white;
    width: 100%;
    display: flex;
    position: relative;
    background-image: linear-gradient(
      to top,
      #252525,
      #000000,
      #000000bd,
      #c3c3c300,
      #ffffff00
    );
    overflow-y: clip;
    position: absolute;
    bottom: 0;
    z-index: 2;
    height: 415px;
    ${breakpoints.phone} {
      min-height: 350px;
    }
  }
  .mt {
    position: absolute;
    bottom: 60px;
    z-index: 3;
    left: 40px;
    display: inline-block;
    width: 60%;
   
    ${breakpoints.phone} {
      bottom: 30px;
      left: 17px;
      width: 90%;
    }
    h2 {
      line-height: 50px;
      font-size: 46px;
      ${Font.clarinVarSub2Bold};
      text-shadow: 0 0 9px #101010;
      display: inline;
      color: #fff;
      ${breakpoints.phone} {
        font-size: 31px;
        line-height: 34px;
      }
    }
  }

  ul {
    position: relative;
    height: 100%;
  }

  .slide-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 0.2s ease-out;
    height: 100%;
    &.slide-active {
      opacity: 1;
      transform: translateX(0);
    }
    &.slide-preview-prev {
      transform: translateX(-100%);
    }
    &.slide-preview-next {
      transform: translateX(100%);
    }
  }

  picture {
    height: 100%;
    position: relative;
    img {
      width: 100%;
      object-fit: cover;
      height: auto;
      ${breakpoints.phone} {
        height: 100%;
      }
    }
  } */
`;

export const VisualArticleSlide = styled.div`
  /* position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; */

`;